<!-- 基础服务填报 -->
<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="base-container">
    <header-area :stepActive="1"></header-area>

    <!-- <div class="methods">
      报价方式
      <el-button type="primary">按量付费</el-button>
      <el-button>包年/包月</el-button>
      <span>（包年/包月该方式待策划中）</span>
      <span>（不用线上显示）</span>
    </div> -->
    <div class="fourth">
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-width="150px"
        label-position="left"
      >
        <div class="shoparea">
          <el-form-item v-show="hisShopList.length">
            <el-checkbox v-model="checked">是否关联此客户当前店铺</el-checkbox>
          </el-form-item>

          <el-form-item label="关联店铺" v-show="hisShopList.length">
            <el-select
              v-model="shopDataId"
              @change="(val) => shopOldChange(val)"
              placeholder="请选择"
              clearable
              filterable
            >
              <el-option
                v-for="(item, i) in hisShopList"
                :key="i"
                :label="item.shopName || '--'"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-show="checkHisShopList.length">
            <div class="orderShop-old">
              <div
                v-for="(item, index) in checkHisShopList"
                :key="index"
                class="oldItem"
              >
                {{ item.shopName || "--"
                }}<el-popconfirm
                  title="确定删除吗？"
                  @confirm="() => deleteShop(index)"
                  ><span slot="reference" class="close">×</span>
                </el-popconfirm>
              </div>
            </div>
          </el-form-item>
        </div>
        <!-- 历史店铺 -->
        <template v-if="shopCheckedList.length">
          <!-- 历史 -->
          <div class="td">
          <div v-for="(item, i) in shopCheckedList" :key="i" class="formItem">
            <div class="form-bottom">
              <div class="left-form">
                <el-form-item label="店铺logo" prop="">
                  <div v-if="item.shopLogo" class="container-logo">
                    <img class="img" :src="item.shopLogo" alt="" />
                  </div>
                  <div class="upload-icon-container">
                    <el-button class="common-screen-btn">选择图片</el-button>
                    <input
                      type="file"
                      class="upload-icon-input"
                      @input="chooseFile($event, i, 'his')"
                    />
                  </div>
                </el-form-item>
                <el-form-item label="店铺名称" prop="">
                  <el-input
                    v-model="item.shopName"
                    placeholder="请输入内容"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item label="店铺链接" prop="">
                  <el-input
                    v-model="item.shopUrl"
                    placeholder="请输入内容"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="店铺所在平台"
                  prop="platformId"
                  :rules="rules.platformId"
                >
                  <el-select v-model="item.platformId" placeholder="请选择" clearable filterable >
                  <el-option v-for="item2 in platList" :key="item2.id" :label="item2.platformName" :value="item2.id" > </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="店铺所在类目"
                  prop="categoryId"
                  :rules="rules.categoryId"
                >
                  <el-select v-model="item.categoryId" placeholder="请搜索" clearable filterable remote :remote-method="(val)=>{remoteCategory(val)}" @focus="selectClear">
                    <el-option v-for="item2 in categoryList" :key="item2.id" :label="item2.platformName" :value="item2.id" > </el-option>
                    <div class="common-select-page">
                        <div v-if="categoryParams.pageNum != 1" @click="() => {selectPage('up')}">上一页</div>
                        <div v-if="categoryParams.pageNum == 1" style="color: #999">上一页</div>
                        <div v-if="categoryList && categoryList.length == 10" @click="() => {selectPage('down')}">下一页</div>
                        <div v-if="categoryList && categoryList.length < 10" style="color: #999">下一页</div>
                    </div>
                </el-select>
                </el-form-item>
              </div>
              <div class="right-form">
                <el-form-item
                  label="客服类型选择"
                  prop="serviceType"
                  :rules="rules.serviceType"
                >
                  <div style="display: flex;justify-content: space-between;">
                    <div>
                      <el-button
                        :class="{
                          'ser-btn': item.serviceType == 2,
                          'ser-btn2':
                            item.serviceType == 0 ||
                            item.serviceType == 1 ||
                            item.serviceType == 3
                        }"
                        @click="
                          () => {
                            changeBtn(item, 2);
                          }
                        "
                        >金牌客服</el-button
                      >
                      <el-button
                        :class="{
                          'ser-btn': item.serviceType == 1,
                          'ser-btn2':
                            item.serviceType == 0 ||
                            item.serviceType == 2 ||
                            item.serviceType == 3
                        }"
                        @click="
                          () => {
                            changeBtn(item, 1);
                          }
                        "
                        >普通客服</el-button
                      >
                      <el-button
                        :class="{
                          'ser-btn': item.serviceType == 3,
                          'ser-btn2':
                            item.serviceType == 0 ||
                            item.serviceType == 1 ||
                            item.serviceType == 2
                        }"
                        @click="
                          () => {
                            changeBtn(item, 3);
                          }
                        "
                        >临时客服
                      </el-button>
                    </div>
                    <el-popconfirm
                      title="确定删除吗？"
                      @confirm="
                        () => {
                          delSearchCusList(i, '');
                        }
                      "
                    >
                      <el-button slot="reference" class="del-btn"
                        >删除</el-button
                      >
                    </el-popconfirm>
                  </div>
                  <p>
                    <span style="color: #999999;"
                      >金牌客服：适合对服务质量有高要求的商家选择，可按照商家需求定制。垂直领域3年以 </span
                    ><br />
                    <span class="mo-cus"
                      >上电商客服，积累了大量相关行业的专业知识以及服务经验，能够独立应对各</span
                    ><br />
                    <span class="mo-cus"
                      >种店铺突发情况，更好的提升店铺的转化率。</span
                    >
                  </p>
                  <p>
                    <span style="color: #999999;"
                      >普通客服：适合对接待效率有要求的商家选择，“高性价比”1-2年电商客服从业经验，</span
                    ><br />
                    <span class="mo-cus"
                      >通过三圆系统化培训后上岗，熟悉平台软件、第三方插件等操作，拥有极高的</span
                    ><br />
                    <span class="mo-cus">响应速度和专业的服务话术。</span>
                  </p>
                  <p>
                    <span style="color: #999999;"
                      >临时客服：适用于大促期间人手不足的商家选择，争分夺秒，上岗迅速。资深电商客服，</span
                    ><br />
                    <span class="mo-cus"
                      >了解历年各平台大促规则玩法，对接完成10分钟即可上岗接待。</span
                    >
                  </p>
                </el-form-item>
                <el-form-item label="咨询量单价">
                  <span class="cons">{{ price }}(元)</span>
                </el-form-item>
                <el-form-item label="协议子账号数量" prop="">
                  <div
                    style="display: flex;justify-content: space-between;align-items: center"
                  >
                    <el-input
                      v-model="item.subaccountNum"
                      placeholder="请输入内容"
                      class="small-ipt"
                      clearable
                      @change="
                        () => {
                          comPlatMax(i, 'his');
                        }
                      "
                    ></el-input>
                  </div>
                </el-form-item>
                <el-form-item label="客服人力" prop=""
                  ><el-input
                    v-model="item.serviceNum"
                    class="mini-ipt"
                    @change="
                    () => {
                      saveBase('totalprice');
                    }
                  "
                  ></el-input>
                  <span style="margin-left: 10px;">人</span>
                  <span class="default-btn"
                    >（默认根据平台单个客服最大子账号数计算需要人力）</span
                  >
                </el-form-item>
                <el-form-item
                  label="协议客服工作时间"
                  prop="hour"
                  :rules="rules.hour"
                >
                  <el-time-picker
                    is-range
                    v-model="item.hour"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    placeholder="选择时间范围"
                    value-format="HH:mm"
                    format="HH:mm"
                    @change="
                      () => {
                        hisTimeChange(i);
                      }
                    "
                  >
                  </el-time-picker>
                </el-form-item>
                <el-form-item label="客服工作时长" prop=""
                  ><el-input
                    v-model="item.workHour"
                    disabled
                    class="mini-ipt"
                  ></el-input>
                  <span style="margin-left: 10px;">小时</span>
                </el-form-item>
                <el-form-item
                  label="协议子账号日咨询量"
                  prop="shopConsultationNum"
                  :rules="rules.shopConsultationNum"
                  ><el-input
                    v-model="item.shopConsultationNum"
                    placeholder="请输入内容"
                    class="small-ipt"
                    clearable
                    @change="
                      () => {
                        saveBase('totalprice');
                      }
                    "
                  ></el-input>
                  <p style="color: #999999;">
                    咨询量：店铺每天咨询总人数；店铺实际咨询量以测试通过后的真实平均咨询量为准。
                  </p>
                </el-form-item>
                <el-form-item
                  label="服务时间范围"
                  prop="date"
                  :rules="rules.date"
                >
                  <el-date-picker
                    v-model="item.date"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    clearable
                    @change="
                      () => {
                        hisDateChange(i);
                      }
                    "
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="店铺服务天数" prop="">
                  <el-input
                    v-model="item.serviceDay"
                    disabled
                    class="mini-ipt"
                  ></el-input
                  ><span style="margin-left: 10px;">天</span>
                  <span class="default-btn">（根据服务时间范围自动结算）</span>
                </el-form-item>
              </div>
            </div>
          </div>
          </div>
          <!-- 历史新增店铺 -->
          <div :style="{ display: list.length ? 'block' : 'none' }">
            <!-- <div
              class="form-bottom"
              v-for="(item, i) in list.slice(0, list.length - 1)"
              :key="i"
            > -->
            <div class="form-bottom" v-for="(item, i) in list" :key="i">
              <div class="left-form">
                <el-form-item label="店铺logo" prop="">
                  <div v-if="list[i].shopLogo" class="container-logo">
                    <img class="img" :src="list[i].shopLogo" alt="" />
                  </div>
                  <div class="upload-icon-container">
                    <el-button class="common-screen-btn">选择图片</el-button>
                    <input
                      type="file"
                      class="upload-icon-input"
                      @input="chooseFile($event, i)"
                    />
                  </div>
                </el-form-item>
                <el-form-item label="店铺名称" prop="">
                  <el-input
                    v-model="list[i].shopName"
                    placeholder="请输入内容"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item label="店铺链接" prop="">
                  <el-input
                    v-model="list[i].shopUrl"
                    placeholder="请输入内容"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="店铺所在平台"
                  prop="platformId"
                  :rules="rules.platformId"
                >
                  <el-select v-model="list[i].platformId" placeholder="请选择" clearable filterable >
                  <el-option v-for="item2 in platList" :key="item2.id" :label="item2.platformName" :value="item2.id" > </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="店铺所在类目"
                  prop="categoryId"
                  :rules="rules.categoryId"
                >
                  <el-select v-model="list[i].categoryId" placeholder="请搜索" clearable filterable remote :remote-method="(val)=>{remoteCategory(val)}" @focus="selectClear">
                    <el-option v-for="item2 in categoryList" :key="item2.id" :label="item2.platformName" :value="item2.id" > </el-option>
                    <div class="common-select-page">
                        <div v-if="categoryParams.pageNum != 1" @click="() => {selectPage('up')}">上一页</div>
                        <div v-if="categoryParams.pageNum == 1" style="color: #999">上一页</div>
                        <div v-if="categoryList && categoryList.length == 10" @click="() => {selectPage('down')}">下一页</div>
                        <div v-if="categoryList && categoryList.length < 10" style="color: #999">下一页</div>
                    </div>
                </el-select>
                </el-form-item>
              </div>
              <div class="right-form">
                <el-form-item
                  label="客服类型选择"
                  prop="serviceType"
                  :rules="rules.serviceType"
                >
                  <div style="display: flex;justify-content: space-between;">
                    <div>
                      <el-button
                        :class="{
                          'ser-btn': item.serviceType == 2,
                          'ser-btn2':
                            item.serviceType == 0 ||
                            item.serviceType == 1 ||
                            item.serviceType == 3
                        }"
                        @click="
                          () => {
                            changeBtn(item, 2);
                          }
                        "
                        >金牌客服</el-button
                      >
                      <el-button
                        :class="{
                          'ser-btn': item.serviceType == 1,
                          'ser-btn2':
                            item.serviceType == 0 ||
                            item.serviceType == 2 ||
                            item.serviceType == 3
                        }"
                        @click="
                          () => {
                            changeBtn(item, 1);
                          }
                        "
                        >普通客服</el-button
                      >
                      <el-button
                        :class="{
                          'ser-btn': item.serviceType == 3,
                          'ser-btn2':
                            item.serviceType == 0 ||
                            item.serviceType == 1 ||
                            item.serviceType == 2
                        }"
                        @click="
                          () => {
                            changeBtn(item, 3);
                          }
                        "
                        >临时客服
                      </el-button>
                    </div>
                    <el-popconfirm
                      title="确定删除吗？"
                      @confirm="
                        () => {
                          delSearchCusList('', i);
                        }
                      "
                    >
                      <el-button slot="reference" class="del-btn"
                        >删除</el-button
                      >
                    </el-popconfirm>
                  </div>
                  <p>
                    <span style="color: #999999;"
                      >金牌客服：适合对服务质量有高要求的商家选择，可按照商家需求定制。垂直领域3年以 </span
                    ><br />
                    <span class="mo-cus"
                      >上电商客服，积累了大量相关行业的专业知识以及服务经验，能够独立应对各</span
                    ><br />
                    <span class="mo-cus"
                      >种店铺突发情况，更好的提升店铺的转化率。</span
                    >
                  </p>
                  <p>
                    <span style="color: #999999;"
                      >普通客服：适合对接待效率有要求的商家选择，“高性价比”1-2年电商客服从业经验，</span
                    ><br />
                    <span class="mo-cus"
                      >通过三圆系统化培训后上岗，熟悉平台软件、第三方插件等操作，拥有极高的</span
                    ><br />
                    <span class="mo-cus">响应速度和专业的服务话术。</span>
                  </p>
                  <p>
                    <span style="color: #999999;"
                      >临时客服：适用于大促期间人手不足的商家选择，争分夺秒，上岗迅速。资深电商客服，</span
                    ><br />
                    <span class="mo-cus"
                      >了解历年各平台大促规则玩法，对接完成10分钟即可上岗接待。</span
                    >
                  </p>
                </el-form-item>
                <el-form-item label="咨询量单价">
                  <span class="cons">{{ price }}(元)</span>
                </el-form-item>
                <el-form-item label="协议子账号数量" prop="">
                  <div
                    style="display: flex;justify-content: space-between;align-items: center"
                  >
                    <el-input
                      v-model="list[i].subaccountNum"
                      placeholder="请输入内容"
                      class="small-ipt"
                      @change="
                        () => {
                          comPlatMax(i, 'neo');
                        }
                      "
                      clearable
                    ></el-input>
                  </div>
                </el-form-item>
                <el-form-item label="客服人力" prop="serviceNum">
                  <el-input
                    v-model="list[i].serviceNum"
                    class="mini-ipt"
                    @change="
                    () => {
                      saveBase('totalprice');
                    }
                  "
                  ></el-input>
                  <span style="margin-left: 10px;">人</span>
                  <span class="default-btn"
                    >（默认根据平台单个客服最大子账号数计算需要人力）</span
                  >
                </el-form-item>
                <el-form-item
                  label="协议客服工作时间"
                  prop="hour"
                  :rules="rules.hour"
                >
                  <el-time-picker
                    is-range
                    v-model="list[i].hour"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    placeholder="选择时间范围"
                    value-format="HH:mm"
                    format="HH:mm"
                    @change="hourChange(i)"
                  >
                  </el-time-picker>
                </el-form-item>
                <el-form-item label="客服工作时长" prop=""
                  ><el-input
                    v-model="list[i].workHour"
                    disabled
                    class="mini-ipt"
                  ></el-input>
                  <span style="margin-left: 10px;">小时</span>
                </el-form-item>
                <el-form-item
                  label="协议子账号日咨询量"
                  prop="shopConsultationNum"
                  :rules="rules.shopConsultationNum"
                  ><el-input
                    v-model="list[i].shopConsultationNum"
                    placeholder="请输入内容"
                    class="small-ipt"
                    clearable
                    @change="
                      () => {
                        saveBase('totalprice');
                      }
                    "
                  ></el-input>
                  <p style="color: #999999;">
                    咨询量：店铺每天咨询总人数；店铺实际咨询量以测试通过后的真实平均咨询量为准。
                  </p>
                </el-form-item>
                <el-form-item
                  label="服务时间范围"
                  prop="date"
                  :rules="rules.date"
                >
                  <el-date-picker
                    v-model="list[i].date"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    @change="timeChange(i)"
                    clearable
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="店铺服务天数" prop="">
                  <el-input
                    v-model="list[i].serviceDay"
                    disabled
                    class="mini-ipt"
                    clearable
                  ></el-input
                  ><span style="margin-left: 10px;">天</span>
                  <span class="default-btn">（根据服务时间范围自动结算）</span>
                </el-form-item>
              </div>
            </div>
          </div>
        </template>

        <template v-else-if="list.length">
          <div class="newtd">
          <div class="form-bottom" v-for="(item, i) in list" :key="i">
            <div class="left-form">
              <el-form-item label="店铺logo" prop="">
                <div v-if="list[i].shopLogo" class="container-logo">
                  <img class="img" :src="list[i].shopLogo" alt="" />
                </div>
                <div class="upload-icon-container">
                  <el-button class="common-screen-btn">选择图片</el-button>
                  <input
                    type="file"
                    class="upload-icon-input"
                    @input="chooseFile($event, i)"
                  />
                </div>
              </el-form-item>
              <el-form-item label="店铺名称" prop="">
                <el-input
                  v-model="list[i].shopName"
                  placeholder="请输入内容"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="店铺链接" prop="">
                <el-input
                  v-model="list[i].shopUrl"
                  placeholder="请输入内容"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item
                  label="店铺所在平台"
                  prop="platformId"
                  :rules="rules.platformId"
                >
                  <el-select v-model="list[i].platformId" placeholder="请选择" clearable filterable >
                  <el-option v-for="item2 in platList" :key="item2.id" :label="item2.platformName" :value="item2.id" > </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="店铺所在类目"
                  prop="categoryId"
                  :rules="rules.categoryId"
                >
                  <el-select v-model="list[i].categoryId" placeholder="请搜索" clearable filterable remote :remote-method="(val)=>{remoteCategory(val)}" @focus="selectClear">
                    <el-option v-for="item2 in categoryList" :key="item2.id" :label="item2.platformName" :value="item2.id" > </el-option>
                    <div class="common-select-page">
                        <div v-if="categoryParams.pageNum != 1" @click="() => {selectPage('up')}">上一页</div>
                        <div v-if="categoryParams.pageNum == 1" style="color: #999">上一页</div>
                        <div v-if="categoryList && categoryList.length == 10" @click="() => {selectPage('down')}">下一页</div>
                        <div v-if="categoryList && categoryList.length < 10" style="color: #999">下一页</div>
                    </div>
                </el-select>
                </el-form-item>
            </div>
            <div class="right-form">
              <el-form-item
                label="客服类型选择"
                prop="serviceType"
                :rules="rules.serviceType"
              >
                <div style="display: flex;justify-content: space-between;">
                  <div>
                    <el-button
                      :class="{
                        'ser-btn': item.serviceType == 2,
                        'ser-btn2':
                          item.serviceType == 0 ||
                          item.serviceType == 1 ||
                          item.serviceType == 3
                      }"
                      @click="
                        () => {
                          changeBtn(item, 2);
                        }
                      "
                      >金牌客服</el-button
                    >
                    <el-button
                      :class="{
                        'ser-btn': item.serviceType == 1,
                        'ser-btn2':
                          item.serviceType == 0 ||
                          item.serviceType == 2 ||
                          item.serviceType == 3
                      }"
                      @click="
                        () => {
                          changeBtn(item, 1);
                        }
                      "
                      >普通客服</el-button
                    >
                    <el-button
                      :class="{
                        'ser-btn': item.serviceType == 3,
                        'ser-btn2':
                          item.serviceType == 0 ||
                          item.serviceType == 1 ||
                          item.serviceType == 2
                      }"
                      @click="
                        () => {
                          changeBtn(item, 3);
                        }
                      "
                      >临时客服
                    </el-button>
                  </div>
                  <el-popconfirm
                    title="这是一段内容确定删除吗？"
                    @confirm="
                      () => {
                        delSearchCusList('', i);
                      }
                    "
                  >
                    <el-button slot="reference" class="del-btn">删除</el-button>
                  </el-popconfirm>
                </div>
                <p>
                  <span style="color: #999999;"
                    >金牌客服：适合对服务质量有高要求的商家选择，可按照商家需求定制。垂直领域3年以 </span
                  ><br />
                  <span class="mo-cus"
                    >上电商客服，积累了大量相关行业的专业知识以及服务经验，能够独立应对各</span
                  ><br />
                  <span class="mo-cus"
                    >种店铺突发情况，更好的提升店铺的转化率。</span
                  >
                </p>
                <p>
                  <span style="color: #999999;"
                    >普通客服：适合对接待效率有要求的商家选择，“高性价比”1-2年电商客服从业经验，</span
                  ><br />
                  <span class="mo-cus"
                    >通过三圆系统化培训后上岗，熟悉平台软件、第三方插件等操作，拥有极高的</span
                  ><br />
                  <span class="mo-cus">响应速度和专业的服务话术。</span>
                </p>
                <p>
                  <span style="color: #999999;"
                    >临时客服：适用于大促期间人手不足的商家选择，争分夺秒，上岗迅速。资深电商客服，</span
                  ><br />
                  <span class="mo-cus"
                    >了解历年各平台大促规则玩法，对接完成10分钟即可上岗接待。</span
                  >
                </p>
              </el-form-item>
              <el-form-item label="咨询量单价">
                <span class="cons">{{ price }}(元)</span>
              </el-form-item>
              <el-form-item label="协议子账号数量" prop="">
                <div
                  style="display: flex;justify-content: space-between;align-items: center"
                >
                  <el-input
                    v-model="list[i].subaccountNum"
                    placeholder="请输入内容"
                    class="small-ipt"
                    @change="
                      () => {
                        comPlatMax(i, 'neo');
                      }
                    "
                    clearable
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item label="客服人力" prop="">
                <el-input
                  v-model="list[i].serviceNum"
                  class="mini-ipt"
                  @change="
                    () => {
                      saveBase('totalprice');
                    }
                  "
                ></el-input>
                <span style="margin-left: 10px;">人</span>
                <span class="default-btn"
                  >（默认根据平台单个客服最大子账号数计算需要人力）</span
                >
              </el-form-item>
              <el-form-item
                label="协议客服工作时间"
                prop="hour"
                :rules="rules.hour"
              >
                <el-time-picker
                  is-range
                  v-model="list[i].hour"
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  placeholder="选择时间范围"
                  value-format="HH:mm"
                  format="HH:mm"
                  @change="hourChange(i)"
                >
                </el-time-picker>
              </el-form-item>
              <el-form-item label="客服工作时长" prop=""
                ><el-input
                  v-model="list[i].workHour"
                  disabled
                  class="mini-ipt"
                ></el-input>
                <span style="margin-left: 10px;">小时</span>
              </el-form-item>
              <el-form-item
                label="协议子账号日咨询量"
                prop="shopConsultationNum"
                :rules="rules.shopConsultationNum"
                ><el-input
                  v-model="list[i].shopConsultationNum"
                  placeholder="请输入内容"
                  class="small-ipt"
                  clearable
                  @change="
                    () => {
                      saveBase('totalprice');
                    }
                  "
                ></el-input>
                <p style="color: #999999;">
                  咨询量：店铺每天咨询总人数；店铺实际咨询量以测试通过后的真实平均咨询量为准。
                </p>
              </el-form-item>
              <el-form-item
                label="服务时间范围"
                prop="date"
                :rules="rules.date"
              >
                <el-date-picker
                  v-model="list[i].date"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="timeChange(i)"
                  clearable
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="店铺服务天数" prop="">
                <el-input
                  v-model="list[i].serviceDay"
                  disabled
                  class="mini-ipt"
                ></el-input
                ><span style="margin-left: 10px;">天</span>
                <span class="default-btn">（根据服务时间范围自动结算）</span>
              </el-form-item>
            </div>
          </div>
          </div>
        </template>

        <!-- 没有显示图片 -->
        <template v-else>
          <el-empty :image="nullImg" description="暂无数据,请添加店铺"></el-empty>
        </template>
      </el-form>
    </div>
    <div class="gap"></div>
    <div class="fifth">
      该订单还可添加更多店铺
      <el-button @click="addShop" type="text">（添加店铺）</el-button>
    </div>
    <div class="gap3"></div>
    <div class="sixth">
      <el-button
        @click="
          () => {
            reset();
          }
        "
        >重置此页</el-button
      >
      <div style="display: flex;align-items: center;">
        <span class="sett-money">配置费用：</span>
        <span class="sett-rmb">¥</span>
        <span class="sett-total">{{ totalMoney.toFixed(2) }}</span>
        <!-- <el-button
          @click="
            () => {
              saveBase('totalprice');
            }
          "
          type="primary"
          >计算价格</el-button
        > -->
        <!-- <span v-if="true" class="sett-status">(待核算)</span>
        <span v-else class="sett-status">(正在计算价格...)</span> -->
        <el-button
          @click="
            () => {
              $router.push({
                path: '/quoted/newCus',
                query: { id: this.$route.query.id }
              });
            }
          "
          plain
          class="sett-return"
          >上一步：客户报价</el-button
        >
        <el-button
          @click="
            () => {
              saveBase();
            }
          "
          type="primary"
          >下一步：配置服务内容</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import { upload } from "../../service/upload.js";
import { DateTransform, Config } from "../../utils/index.js";
import {
  baseSerach,
  baseSave,
  getPrice,
  total,
  relationShop
} from "../../service/quoted.js";
// import { Cascader } from "ant-design-vue";
// import { getPlatList } from "../../service/common.js";
import { selectCategory, selectPlatform } from "../../service/common.js";
import nullImg from "@/assets/images/no.png"

import HeaderArea from "./components/header.vue";

export default {
  name: "baseServices",
  components: {
    // aCascader: Cascader,
    HeaderArea
  },
  props: {},
  data() {
    return {
      DateTransform,
      Config,
      nullImg,
      form: {
        // relationId: ,
        shopConsultationNum: 100, //协议子账号日咨询量
        date: [new Date(), new Date()],
        hour: ["09:00", "17:00"],
        serviceDay: 1,
        serviceType: 2,
        platformId: 1,//默认
        categoryId: 1, //默认
        workHour: 8.0
      },
      rules: {
        serviceType: [
          { required: true, message: "请选择客服类型", trigger: "change" }
        ],
        shopConsultationNum: [
          {
            required: true,
            message: "请选择协议子账号日咨询量",
            trigger: "change"
          }
        ],
        platformId: [ //平台
          { required: true, message: "请选择平台", trigger: "change" }
        ],
        categoryId: [ //类目
          { required: true, message: "请选择类目", trigger: "change" }
        ]
        ,
        date: [
          { required: true, message: "请选择服务时间范围", trigger: "change" }
        ],
        hour: [
          {
            required: true,
            message: "请选择协议客服工作时间",
            trigger: "change"
          }
        ],
        shopName: [{ required: true, message: "请输入店铺名", trigger: "change" }]
      },
      list: [
        // {
        // id: 0,
        // shopLogo: "",
        // shopName: "",
        // shopUrl: "",
        // serviceType: 1,
        // subaccountNum: 1,
        // serviceNum: 1,
        // shopConsultationNum: 100,
        // date: [new Date(), new Date()],
        // hour: ["09:00", "17:00"],
        // serviceDay: 1,
        // platformIds: [43],
        // workHour: 8.0
        // }
      ],
      checked: true,
      input: "",
      value1: "",
      headerInfo: {},
      searchCusList: [], //第一次进来查询报价信息
      shopCheckedList: [], //
      platList: [], // 所属平台下拉\
      categoryList: [], // 所属类目下拉\
      categoryParams: { //平台类目下拉条件
        pageNum: 1,
        pageSize: 10,
        name: ''
      },
      divNum: 1,
      divNum0: 0,
      platformMax: 0,
      num: [1, 2],
      price: "",
      people: 0,
      loading: false,
      totalMoney: 0,
      shopDataId: "",
      // /////////////获取最上面客户关联店铺 /////////////
      cusId: this.$route.query.cusId, //查询的客户ID
      hisShopList: [], //客户关联店铺列表
      checkHisShopList: [] //选中的客户关联店铺列表
    };
  },
  //监听属性 类似于data概念
  computed: {
  },
  //监控data中的数据变化
  watch: {
    checked(val, oldVal) {
      console.log(oldVal, "-----------");
      if (!val) {
        this.shopCheckedList = this.searchCusList;
        this.checkHisShopList = [];
      } else {
        // 合并
        this.shopCheckedList = this.hisShopList.concat(this.searchCusList);
        this.checkHisShopList = this.hisShopList;
      }
    }
  },
  //方法集合
  methods: {
    selectClear () { // 下拉清空时触发
        this.categoryParams.pageNum = 1
        this.categoryParams.name = ''
        this.selectCategory()
    },
    selectPage(type) { // 下拉分页
        if (type == 'up') {
            this.categoryParams.pageNum--
        } else {
            this.categoryParams.pageNum++
        }
        this.selectCategory()
    },
    remoteCategory(val) { // 所属类目下拉-筛选
        console.log(val,'类目输入值*******');
        this.categoryParams.pageNum = 1
        this.categoryParams.name = val
        this.selectCategory()
    },
    async selectCategory() { // 类目下拉
        let categoryParams = {...this.categoryParams}
        let { data } = await selectCategory(categoryParams)
        this.categoryList = data.list
        this.form.categoryId = data.list[0].id
    },
    async selectPlatform() { // 平台下拉
        let { data } = await selectPlatform({pageNum: -1})
        this.platList = data
        this.form.platformId = data[0].id
    },
    // 获单价
    async getPrices() {
      const res = await getPrice();
      this.price = res.data;
    },
    // async getPlatList() {
    //   // 所属平台/类目下拉
    //   let resData = (await getPlatList({ pageNum: -1 })).data;
    //   this.platList = resData;
    // },
    async chooseFile(e, i, type) {
      this.loading = true;
      let file = e.target.files[0];
      if (file.size / 1024 > 1024 * 50) {
        this.loading = false;
        e.target.value = "";
        return this.$message.error("文件大小不能超过50M");
      }
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (!Config.fileType.imgType.includes(format)) {
        e.target.value = "";
        this.loading = false;
        return this.$message.error(
          `只能上传 ${Config.fileMessage.imgType} 格式的文件`
        );
      }

      let resData = (await upload({ file: e.target.files[0] })).data;
      if (type == "his") {
        this.shopCheckedList[i].shopLogo = resData.filename;
        this.searchCusList[i].shopLogo = resData.filename;
      } else {
        this.list[i].shopLogo = resData.filename;
      }
      e.target.value = "";
      this.loading = false;
      // this.form = Object.assign(form, {
      //   shopLogo: resData.filename
      // });
    },
    // 查询信息
    async searchCus(id) {
      this.searchCusList = [];
      const { data } = await baseSerach({}, id);
      console.log(data, "基础服务填报查询接口two");
      // 增加数组为了校验
      data.forEach((item) => {
        item.date = [item.startTime, item.endTime];
        // item.platformIds = [item.platformId, item.categoryId];
        // item.platformIds = [item.platformId ]; //平台
        // item.categoryIds = [item.categoryId] //类目
        item.hour = [item.startHour, item.endHour];
        this.searchCusList.push(item);
        this.remoteCategory(item.categoryName) //根据类名搜索
      });
      
      this.shopCheckedList = this.searchCusList;
      if (this.cusId) {
        console.log("nnnnnnnnnn");

        this.shopCheckedList = this.unique([
          ...this.checkHisShopList,
          ...this.shopCheckedList
        ]);
      } else {
        this.shopCheckedList = this.searchCusList;
      }
      // 有数据调计算价格
      if (this.shopCheckedList && this.shopCheckedList.length) {
        this.form = this.shopCheckedList[this.shopCheckedList.length - 1];
        this.saveBase("totalprice");
      } 
      // else {
      //   // 无，新增一个占位
      //   // 有默认值，因为change会计算
      //   this.list.push({
      //     id: 0,
      //     shopLogo: "",
      //     shopName: "",
      //     shopUrl: "",
      //     serviceType: 2,
      //     subaccountNum: 1,
      //     serviceNum: 1,
      //     shopConsultationNum: 100,
      //     date: [new Date(), new Date()],
      //     hour: ["09:00", "17:00"],
      //     serviceDay: 1,
      //     platformIds: [43],
      //     workHour: 8.0
      //   });
      // }

      // this.loading = false;
    },
    deleteShop(i) {
      let shopCheckedList = [...this.checkHisShopList];
      shopCheckedList = shopCheckedList.filter((item, index) => {
        if (item) {
          return index != i;
        }
      });
      this.checkHisShopList = shopCheckedList;
      // 下面也删除
      this.shopCheckedList = shopCheckedList;
      // 计算价格
      this.saveBase("totalprice");
    },
    shopOldChange(e) {
      let shopOldList = [...this.hisShopList];
      this.form = shopOldList[0];
      let shopCheckedList = [...this.checkHisShopList];
      let isExistence = false;
      shopCheckedList.map((item) => {
        if (item.id == e) {
          isExistence = true;
        }
      });
      if (isExistence) {
        return this.$message.error("已选择");
      }
      if (e) {
        shopCheckedList.push(
          ...shopOldList.filter((item) => {
            return item.id == e;
          })
        );
        this.shopCheckedList = shopOldList.filter((item) => {
          return item.id == e;
        });
      }
      this.checkHisShopList = shopCheckedList;

      // 查询出来的，下拉选中添加到下面
      // this.shopCheckedList.unshift(shopCheckedList[shopCheckedList.length - 1]);
    },
    // 删除店铺
    delSearchCusList(hisIndex, i) {
      console.log(hisIndex, i, "-----");
      if (hisIndex.toString()) {
        this.shopCheckedList.splice(hisIndex, 1);
        this.checkHisShopList.splice(hisIndex, 1);
      } else if (i.toString()) {
        this.list.splice(i, 1);
      }
      this.saveBase("totalprice");
    },
    // 保存基础服务 || 计算价格
    saveBase(type) {
      this.loading = true;

      // if (!this.shopCheckedList.length && !this.list.length) {
      //   this.loading = false;
      //   return this.$message.error("请先添加店铺信息");
      // }

      this.$refs["form"].validate(async (valid) => {
        console.log(valid, "11111369");
        if (valid) {
          let params = {};
          params.relationId = this.$route.query.id;
          let shopData = [];
          params.shopData = shopData;
          // 历史店铺
          this.shopCheckedList.forEach((item) => {
            if (item.date && item.date.length) {
              item.startTime = DateTransform(item.date[0]);
              item.endTime = DateTransform(item.date[1]);
              // delete item.date;
            }
            if (item.hour && item.hour.length) {
              item.startHour = item.hour[0];
              item.endHour = item.hour[1];
              // delete item.hour;
            }
            if (item.platformIds && item.platformIds.length) {
              item.platformId = item.platformIds[0];
              if (item.platformIds[1]) {
                item.categoryId = item.platformIds[1];
              }
            }
            // delete item.platformIds;
            params.shopData.push(item);
          });
          ///////////////////// 新增店铺/////////////////

          // 新增店铺--日期
          let list = [...this.list];

          list.forEach((item) => {
            //--日期
            if (item.date && item.date.length) {
              item.startTime = DateTransform(item.date[0]);
              item.endTime = DateTransform(item.date[1]);
              // delete item.date;
            }

            // 新增店铺--时间
            if (item.hour && item.hour.length) {
              item.startHour = item.hour[0];
              item.endHour = item.hour[1];
              // delete item.hour;
            }
            //--平台类目
            if (item.platformIds && item.platformIds.length) {
              item.platformId = item.platformIds[0];
              if (item.platformIds[1]) {
                item.categoryId = item.platformIds[1];
              }
            }
            // delete item.platformIds;
            params.shopData.push(item);
          });
          console.log(params, "保存基础服务----参数");
          const res = await baseSave(params);

          if (type == "totalprice") {
            const { data } = await total({}, this.$route.query.id);
            if (data && data.price) {
              this.totalMoney = data.price;
            } else {
              this.totalMoney = 0.0;
            }
            // this.getHisShop();
          } else {
            console.log(
              this.shopCheckedList.length,
              this.list.length,
              "判断两个数组"
            );
            if (!this.shopCheckedList.length && !this.list.length) {
              this.loading = false;
              return this.$message.error("请先添加店铺信息");
            } else {
              this.$router.push({
                path: "/quoted/setting-content",
                query: { id: this.$route.query.id, cusId: this.cusId }
              });
            }

            // this.searchCus(this.$route.query.id);
          }

          if (res.code != 200) {
            return this.$message.error("操作失败");
          }
          this.$message.success("操作成功");
          this.loading = false;
        } else {
          this.$message.error("请输入完整信息");
          this.loading = false;
          return false;
        }
      });
    },
    addShop() {
      console.log(this.platList,'添加店铺');
      // this.divNum0 += 1;
      this.list.push({
        id: 0,
        shopLogo: "",
        shopName: "",
        shopUrl: "",
        serviceType: 2,
        subaccountNum: 1,
        serviceNum: 1,
        shopConsultationNum: 100,
        date: [new Date(), new Date()],
        hour: ["09:00", "17:00"],
        serviceDay: 1,
        // platformIds: [this.platList[0].id, (this.platList[0].children[0]).id],
        platformId: this.platList[0].id,
        categoryId: this.categoryList[0].id,
        workHour: 8.0
      });
    },
    platMax(val, selectedOptions, i, type) {
      console.log(val, selectedOptions, i, "------------");
      if (type == "his") {
        const platMax = this.shopCheckedList[i].platform_max;
        if (platMax && this.shopCheckedList[i].subaccountNum) {
          this.$set(
            this.shopCheckedList[i],
            "serviceNum",
            Math.ceil(this.shopCheckedList[i].subaccountNum / platMax)
          );
          // this.people = this.platformMax % this.list[i].subaccountNum;
        } else if (platMax || this.shopCheckedList[i].subaccountNum) {
          this.$set(this.shopCheckedList[i], "serviceNum", 0);
        }
        this.saveBase("totalprice");
      } else {
        if (selectedOptions && selectedOptions.length) {
          this.platformMax = Number(selectedOptions[0].platform_max);
        } else {
          this.platformMax = 0;
        }
        if (this.platformMax && this.list[i].subaccountNum) {
          this.$set(
            this.list[i],
            "serviceNum",
            Math.ceil(this.list[i].subaccountNum / this.platformMax)
          );
          // this.people = this.platformMax % this.list[i].subaccountNum;
        } else if (this.platformMax || this.list[i].subaccountNum) {
          this.$set(this.list[i], "serviceNum", 0);
        }
      }
    },
    //协议子账号数量
    comPlatMax(i, type) {
      if (type == "his") {
        const platMax = this.shopCheckedList[i].platform_max;
        if (platMax && this.shopCheckedList[i].subaccountNum) {
          this.$set(
            this.shopCheckedList[i],
            "serviceNum",
            Math.ceil(this.shopCheckedList[i].subaccountNum / platMax)
          );

          // this.list[i].serviceNum = this.platformMax % this.list[i].subaccountNum;
          console.log(this.shopCheckedList[i].serviceNum);
        } else if (platMax || this.shopCheckedList[i].subaccountNum) {
          this.$set(this.shopCheckedList[i], "serviceNum", 0);
        }
      } else {
        if (!this.platformMax) {
          this.platformMax = "10";
        }
        if (this.platformMax && this.list[i].subaccountNum) {
          console.log("1111111111111111");

          this.$set(
            this.list[i],
            "serviceNum",
            Math.ceil(this.list[i].subaccountNum / this.platformMax)
          );

          // this.list[i].serviceNum = this.platformMax % this.list[i].subaccountNum;
          console.log(this.list[i].serviceNum);
        } else if (this.platformMax || this.list[i].subaccountNum) {
          this.$set(this.list[i], "serviceNum", 0);
        }
      }
      this.saveBase("totalprice");
    },
    timeChange(i) {
      let list = this.list[i];
      list.serviceDay =
        (new Date(list.date[1]).getTime() - new Date(list.date[0]).getTime()) /
          (3600 * 1000 * 24) +
        1;
      this.saveBase("totalprice");
    },
    hourChange(i) {
      let list = this.list[i];
      let h = parseFloat(list.hour[1].split(":")[0]);
      let m = list.hour[1].split(":")[1];
      let h2 = parseFloat(list.hour[0].split(":")[0]);
      let m2 = list.hour[0].split(":")[1];
      list.workHour = (h + m / 60 - (h2 + m2 / 60)).toFixed(1);
      this.saveBase("totalprice");
    },
    // 重置此页
    reset() {
      this.shopCheckedList = [];
      this.checkHisShopList = [];
      // this.searchCus(this.$route.query.id);
      this.list = [];
    },
    changeBtn(item, num) {
      item.serviceType = num;
      this.saveBase("totalprice");
    },
    hisDateChange(i) {
      this.shopCheckedList[i].serviceDay =
        (new Date(this.shopCheckedList[i].date[1]).getTime() -
          new Date(this.shopCheckedList[i].date[0]).getTime()) /
          (3600 * 1000 * 24) +
        1;
      this.saveBase("totalprice");
    },
    hisTimeChange(i) {
      console.log("小时小时");
      let hisList = this.shopCheckedList[i];
      let h = parseFloat(hisList.hour[1].split(":")[0]);
      let m = hisList.hour[1].split(":")[1];
      let h2 = parseFloat(hisList.hour[0].split(":")[0]);
      let m2 = hisList.hour[0].split(":")[1];
      this.shopCheckedList[i].workHour = (h + m / 60 - (h2 + m2 / 60)).toFixed(
        1
      );
      this.saveBase("totalprice");
    },
    unique(arr) {
      // 根据唯一标识shopName来对数组进行过滤
      const res = new Map(); //定义常量 res,值为一个Map对象实例 //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
      return arr.filter(
        (arr) => !res.has(arr.shopName) && res.set(arr.shopName, 1)
      );
    },
    // 获取历史店铺
    async getHisShop() {
      if (this.cusId) {
        //有cusID
        this.checkHisShopList = [];
        this.hisShopList = [];
        this.shopCheckedList = [];
        const { data } = await relationShop({}, this.cusId);
        console.log(data, "正在服务店铺数 (家)");
        this.hisShopList = data;
        //  默认全部添加到下面
        this.hisShopList.forEach((item) => {
          item.platformIds = [item.platformId, item.categoryId];
          // this.$set(item, "id", 0);
          // item.id = 0
          this.$set(item, "serviceType", 2);
          this.$set(item, "hour", ["09:00", "17:00"]);
          this.$set(item, "date", [new Date(), new Date()]);
          this.$set(item, "shopConsultationNum", 100);
          this.$set(item, "subaccountNum", 1);
          this.$set(item, "serviceNum", 1);
          this.$set(item, "workHour", 8);
          this.$set(item, "serviceDay", 1);
          this.$set(item, "platform_max", 10);
          // this.shopCheckedList.push(item);
          this.checkHisShopList.push(item); //拿到赋值
        });
        if (this.checkHisShopList && this.checkHisShopList.length) {
          this.form = this.checkHisShopList[0];
        }
        this.searchCus(this.$route.query.id);
      } else {
        this.searchCus(this.$route.query.id);
      }
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getHisShop();
    // this.searchCus(this.$route.query.id);
    // this.getPlatList();
    this.selectPlatform();
    this.selectCategory();
    this.getPrices();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {}
};
</script>

<style lang="less" scoped>
.base-container {
  height: 100%;
  overflow-y: overlay;
  background: #eee;
  .header {
    background: #fff;
    box-sizing: border-box;
    padding: 25px 127px 32px 100px;
    border-bottom: 1px solid rgba(217, 217, 217, 100);
    .con-title {
      display: flex;
      .tit-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        // width: 330px;
        .address {
          margin-top: 2px;
          font-size: 22px;
        }
        .el-icon-location {
          font-size: 22px;
          margin-right: 8px;
          color: #1890ff;
        }
        .name {
          font-size: 28px;
        }
        .status {
          box-sizing: border-box;
          background-color: #f2f9ff;
          color: #1890ff;
          border: 1px solid #1890ff;
          border-radius: 2px;
          padding: 4px 6px;
          margin-left: 24px;
          font-size: 14px;
          line-height: 16px;
        }
      }
      .tit-center {
        display: flex;
        .center-fir,
        .center-sec {
          display: flex;
          line-height: 42px;
          .center-fir-margin {
            margin-left: 72px;
          }
          .center-fir-third {
            // margin-right: 57px;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              top: 6px;
              left: 104px;
              display: inline-block;
              width: 1px;
              height: 72px;
              background: #bbb;
            }
          }
          .center-fir-con {
            display: inline-block;
            height: 42px;
          }
        }
        .sec-margin {
          margin-left: 110px;
        }
        .sec-width {
          width: 250px;
          text-align: left;
        }
        .center-sec-icon {
          margin-right: 16px;
        }
        .center-sec-con {
          display: inline-block;
          // margin-top: 23px;
          font-size: 20px;
          color: #333;
        }
      }
    }
  }
  .step {
    background: #fff;
    position: relative;
    box-sizing: border-box;
    margin-top: 48px;
    // height: 149px;
    // line-height: 149px;
  }
  .methods {
    height: 96px;
    margin-left: 136px;
    line-height: 96px;
    text-align: left;
  }
  .fourth {
    background: #fff;
    // position: relative;
    text-align: left;
    // padding-top: 32px;
    /deep/ .el-empty{
      height: 296px;
    }
  }
  .form-bottom {
    display: flex;
    // margin-top: 80px;
    padding-top: 32px;
    padding-left: 134px;
    border-top: 16px solid #eee;
  }
  .td{
    .formItem:nth-child(n+2){
    border-top: 16px solid #eee;
    }
    .form-bottom{
      border: none;
    }
  }
  .newtd{
    .form-bottom:first-child{
      border: none;
    }
    .form-bottom:nth-child(n+2){
      border-top: 16px solid #eee;
    }
  }
  .del-btn {
    width: 66px;
    height: 32px;
    padding: 0;
  }

  /deep/ .el-input {
    width: 435px;
  }
  /deep/ .el-textarea {
    width: 435px;
  }
  .small-ipt {
    width: 207px !important;
  }
  .mini-ipt {
    width: 74px !important;
  }
  .left-form {
    display: inline-block;
    margin-right: 100px;
  }
  .right-form {
    position: relative;
    width: 50%;
    display: inline-block;
    padding-left: 103px;
    &::before {
      position: absolute;
      top: 40px;
      left: 0;
      content: "";
      display: inline-block;
      width: 1px;
      height: 375px;
      background: #b5d1ff;
    }
  }
  .price {
    margin-left: 104px;
  }
  .cons {
    font-size: 20px;
  }
  .cus-btn {
    width: 88px;
    height: 32px;
    padding: 0;
  }
  .default-btn {
    margin-left: 14px;
    color: #999999;
  }
  .mo-cus {
    margin-left: 70px;
    color: #999999;
  }
  .upload-icon-input {
    width: 88px;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
  .common-screen-btn {
    margin-left: 0;
  }
  .fifth {
    background: #fff;
    height: 84px;
    line-height: 84px;
    text-align: left;
    padding-left: 134px;
  }
  .sixth {
    float: left;
    background: #fff;
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 0 101px;
    width: 100%;
    height: 84px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 16px solid #eee;
  }
  .sett-money {
    margin-right: 15px;
  }
  .sett-rmb {
    color: #1890ff;
    margin-right: 10px;
  }
  .sett-total {
    color: #1890ff;
    font-size: 36px;
    margin-right: 24px;
  }
  .sett-status {
    color: #999999;
    font-size: 14px;
    margin-right: 24px;
  }
  .sett-return {
    border-color: #1890ff;
    color: #1890ff;
    margin-right: 16px;
  }
  .gap {
    height: 16px;
    background: #eee;
    // margin: 32px 0 0;
  }
  .gap2 {
    // position: absolute;
    top: 255px;
    left: 0;
    right: 0;
    height: 16px;
    background: #eee;
    color: #eee;
  }
  .gaptop {
    top: 235px;
  }
  .gap3 {
    height: 32px;
    background: #eee;
    margin: 0 0 60px;
  }
  .sicon {
    vertical-align: middle;
  }
  .orderShop-old {
    width: 435px;
    height: 100px;
    border-radius: 4px;
    border: #dcdfe6 solid 1px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 0 4px 0;
    .oldItem {
      display: inline-block;
      padding: 0 8px 0 10px;
      line-height: 30px;
      color: #fff;
      background-color: #1890ff;
      border-radius: 4px;
      margin: 2px 0 0 8px;
      .close {
        color: #fff;
        margin-left: 8px;
        cursor: pointer;
        font-size: 18px;
      }
    }
  }
  .ser-btn {
    width: 88px;
    height: 32px;
    padding: 0;
    background: #1890ff;
    color: #fff;
  }
  .ser-btn2 {
    width: 88px;
    height: 32px;
    padding: 0;
    color: #666;
    background: #fff;
  }
  .container-logo {
    margin-right: 16px;
    display: inline-block;
    .img {
      height: 40px;
    }
  }
  .upload-icon-container {
    display: inline-block;
    position: relative;
    width: 88px;
    height: 40px;
    .common-screen-btn {
      margin: 0;
    }
  }
  .shoparea {
    padding-left: 134px;
  }
  .el-form {
    // border-top: 16px solid #eee;
    padding-top: 20px;
    
  }
}
</style>
